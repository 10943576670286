import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { processArticleContent } from '../../utils/processHTMLContent';
import Article from '../../components/Article/Article';
import Seo from '../../components/Seo/Seo';
import {
  getTooltipDetails,
  getAuthorDetails,
  getCategoriesBreadcrumbs,
  getFaqs,
  getRelatedPosts,
  getTemplateName,
  prependUpgradedPointsDomain,
} from '../../utils/helpers';
import { getComments } from '../../utils/helpers/comments';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { getSpecialOffer } from '../../utils/helpers/card';
import { tagsRelatedContent } from '../../data/relatedContent';
import { ITopRelatedContent } from '../../components/TopRelatedContent/TopRelatedContent.def';
import { IFeaturedImageData } from '../../components/Seo/Seo.def';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { HT_DEFAULT_PAGE_NAME } from '../../constants';
interface IDataTemplate {
  post: Queries.WpPost;
}

interface IPageContextTemplate {
  sidebar: Queries.WpUpOption;
  databaseId: number;
  tags: any;
  link: string;
  categories: any;
  specialOffer?: number;
  templateName: string | undefined;
}

const WpDefaultPostTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const {
    post: {
      content,
      author,
      title,
      categories,
      modifiedGmt,
      dateGmt,
      featuredImage,
      commentCount,
      comments: postComments,
      commentStatus,
      template,
      cardMentionsAll,
      nonAffiliateAll,
      fieldsForPosts,
      databaseId,
      postEditorSettings,
      featureImageCredit,
      tags,
      featuredImageOptions,
      uri,
    },
  } = data;
  const sidebarGeneralSettings = pageContext?.sidebar;
  const authorDetails = getAuthorDetails(author?.node);
  const breadCrumbs = getCategoriesBreadcrumbs(categories);
  const numberOfComments = commentCount ? commentCount : 0;
  const comments = getComments(postComments, databaseId);
  const postCategories = categories ? categories.nodes : [];
  const templateName = getTemplateName(template?.templateName);
  const { articleContent, headings } = processArticleContent(content || '', '/TE/', templateName);

  const horizontalPosition = featuredImageOptions?.imageHorizontalPosition;
  const verticalPosition = featuredImageOptions?.imageVerticalPosition;

  const image: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> | null =
    featuredImage?.node?.sourceUrl
      ? {
          src: featuredImage.node.sourceUrl,
          alt: title || '',
          style:
            horizontalPosition && verticalPosition
              ? { objectPosition: `${horizontalPosition} ${verticalPosition}` }
              : {},
        }
      : null;
  const toggleElements: string[] =
    typeof fieldsForPosts?.upgpPostToggleElements?.length !== 'undefined'
      ? fieldsForPosts.upgpPostToggleElements.map((el) => el ?? '')
      : [];
  const faqs = getFaqs(fieldsForPosts?.upgpPostFaqs);
  const relatedPosts = getRelatedPosts(fieldsForPosts?.upgpPostRelatedPosts);
  const overrideSidebar = fieldsForPosts?.upgpOverrideDefaultSidebar ?? false;
  const sidebarPost = fieldsForPosts?.upgpCategorySidebarBlocks;
  const specialOffer = getSpecialOffer(pageContext?.specialOffer);
  const tooltipDetails = getTooltipDetails(author?.node, postEditorSettings);
  const postTags = tags?.nodes || null;
  let topRelatedContent: ITopRelatedContent | null = null;
  if (postTags && postTags?.length) {
    postTags.forEach((tag) => {
      if (tag) {
        const { name } = tag;
        const relatedContentData = tagsRelatedContent.find((content) => content.tagName === name);
        if (relatedContentData) {
          topRelatedContent = {
            categories: relatedContentData.categories,
            title: 'Top Credit Card Content',
          };
        }
      }
    });
  }
  const hasTrustDropdown = fieldsForPosts?.upgpUseTrustDropdown;
  const articleLink = prependUpgradedPointsDomain(uri || '#');
  const categoryList =
    categories?.nodes?.map((cat) => cat?.name || null).reduce((acc, curr) => acc + ', ' + curr) ||
    '';

  return (
    <HTPageWrapper
      category={categoryList || 'Article'}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <Article
        articleHeader={{
          author: authorDetails,
          breadCrumbs,
          dateModified: modifiedGmt || '',
          datePublished: dateGmt || '',
          title: title || '',
          featuredImage: templateName !== 'Full Width (No Hero Image)' ? image : null,
          tooltip: tooltipDetails,
        }}
        toggleElements={toggleElements}
        template={templateName}
        tableOfContents={{ headings, articleLink }}
        articleContent={articleContent}
        featuredImageCredit={{
          creditText: featureImageCredit?.upgpPostFeaturedImageCreditValue || '',
          creditUrl: featureImageCredit?.upgpPostFeaturedImageCreditUrl || '',
          creditUrlExternal: featureImageCredit?.upgpPostFeaturedImageCreditUrlExternal || '',
        }}
        sidebarData={{
          articleId: databaseId || 0,
          sidebar: sidebarGeneralSettings,
          categories: postCategories,
          overrideSidebarPost: overrideSidebar,
          sidebarPost: sidebarPost,
          tags: postTags,
        }}
        authorInfo={{
          authorName: authorDetails.name,
          content: authorDetails.bio || '',
          link: authorDetails.url,
          socials: authorDetails.socials,
          thumbnail: {
            src: authorDetails.imageUrl,
            alt: authorDetails.imageAlt,
          },
        }}
        comments={{
          total: numberOfComments,
          comments,
          postDatabaseId: databaseId,
          commentsClosed: commentStatus === 'closed',
        }}
        cardMentions={cardMentionsAll}
        nonAffiliate={nonAffiliateAll}
        faqs={{
          faqs,
        }}
        postId={databaseId}
        weRecommend={specialOffer}
        relatedPosts={{
          relatedPosts,
        }}
        topRelatedContent={topRelatedContent}
        showTrustDropdown={hasTrustDropdown || false}
        context={{
          databaseId: data?.post?.databaseId || 0,
          tags: data?.post?.tags || null,
          link: data?.post?.uri || '',
          categories: data?.post?.categories || null,
          templateName: data?.post?.template?.templateName || '',
        }}
      />
    </HTPageWrapper>
  );
};

export default WpDefaultPostTemplate;

export const Head = ({ data }: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const { seo, author, featuredImage, fieldsForPosts, categories, tags } = data?.post;
  const isNewsArticle = !!categories?.nodes?.find((cat) => cat?.name === 'News');
  const featuredImageData: IFeaturedImageData = {
    sourceUrl: featuredImage?.node?.sourceUrl || '',
    caption: featuredImage?.node?.caption || '',
    width: featuredImage?.node?.width || 0,
    height: featuredImage?.node?.height || 0,
  };
  return (
    <>
      <Seo
        wpSeo={seo}
        author={author?.node}
        featuredImageData={featuredImageData}
        faqs={fieldsForPosts?.upgpPostFaqs}
        isNewsArticle={isNewsArticle}
        tags={tags}
        categories={categories}
      />
    </>
  );
};

/**
 * The following query contains the GraphQL fragments that can be used later on the other templates.
 */
export const query = graphql`
  fragment PostData on WpPost {
    id
    databaseId
    link
    uri
    author {
      node {
        databaseId
        postsCount
        description
        name
        uri
        avatar {
          url
        }
        userBios {
          upgpUserShortBio
        }
        userSocials {
          upgpUserEmail
          upgpUserSocialLinks {
            upgpUserSocialLinksName
            upgpUserSocialLinksUrl
          }
        }
        upgpAuthorDetails {
          upgpEditorCustomExcerpt
          upgpCountriesVisited
          upgpUsStatesVisited
          upgpEditorJobTitle
          upgpAuthorCountEditedPosts
        }
      }
    }
    featuredImage {
      node {
        sourceUrl
      }
    }
    featuredImageOptions {
      imageHorizontalPosition
      imageVerticalPosition
    }
    featureImageCredit {
      upgpPostFeaturedImageCreditUrl
      upgpPostFeaturedImageCreditUrlExternal
      upgpPostFeaturedImageCreditValue
    }
    title
    categories {
      nodes {
        name
        uri
        slug
      }
    }
    commentCount
    commentStatus
    comments {
      nodes {
        id
        parentId
        author {
          node {
            url
            name
            email
            avatar {
              url
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        content
      }
    }
    modifiedGmt
    dateGmt
    content
    cardMentionsAll
    nonAffiliateAll
    fieldsForPosts {
      upgpPostFaqs {
        ... on WpPost_Fieldsforposts_UpgpPostFaqs_UpgpPostFaq {
          upgpPostAnswer
          upgpPostQuestion
        }
      }
      upgpPostRelatedPosts {
        fieldGroupName
        upgpGbPostRelatedPostsPostPage {
          ... on WpPost {
            id
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
      upgpUseTrustDropdown
      upgpPostToggleElements
      upgpOverrideDefaultSidebar
      upgpCategorySidebarBlocks {
        upgpCategorySibebarBlockName
        upgpCategorySibebarBlockLinks {
          customLinkTitle
          link {
            ... on WpPost {
              databaseId
              title
              uri
            }
          }
        }
      }
    }
    postEditorSettings {
      upgpPostEditor {
        description
        databaseId
        postsCount
        username
        name
        uri
        avatar {
          url
        }
        userBios {
          upgpUserShortBio
        }
        upgpAuthorDetails {
          upgpEditorCustomExcerpt
          upgpCountriesVisited
          upgpUsStatesVisited
          upgpEditorJobTitle
          upgpAuthorCountEditedPosts
        }
      }
      upgpPostEditorTwo {
        description
        databaseId
        postsCount
        username
        name
        uri
        avatar {
          url
        }
        userBios {
          upgpUserShortBio
        }
        upgpAuthorDetails {
          upgpEditorCustomExcerpt
          upgpCountriesVisited
          upgpUsStatesVisited
          upgpEditorJobTitle
          upgpAuthorCountEditedPosts
        }
      }
    }
    seo {
      title
      metaRobotsNofollow
      metaRobotsNoindex
      canonical
      metaDesc
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaType
        mediaDetails {
          height
          width
        }
      }
      opengraphType
      opengraphTitle
      opengraphUrl
      opengraphSiteName
      opengraphPublisher
      opengraphModifiedTime
      readingTime
      schema {
        raw
      }
    }
    tags {
      nodes {
        name
        slug
        databaseId
      }
    }
    template {
      templateName
      ... on WpTemplate_CreditCardReview {
        __typename
      }
      ... on WpTemplate_HotelLoyaltyProgram {
        templateName
        hotelLoyaltyProgramDetails {
          upgpHotelLoyaltyProgramEarnPointsmiles
          upgpHotelLoyaltyProgramBrands
          upgpHotelLoyaltyProgramAwardChartLink
          upgpHotelLoyaltyProgramName
          upgpHotelLoyaltyProgramPointsAreWorth
          upgpHotelLoyaltyProgramRedeemPointsmiles
          upgpHotelLoyaltyProgramReview
          upgpHotelLoyaltyProgramSummaryTableTitle
          upgpHotelLoyaltyProgramWebsite
        }
      }
      ... on WpTemplate_AirlineLoyaltyProgram {
        templateName
        airlineLoyaltyProgramDetails {
          upgpAirlineLoyaltyProgramAirlineAlliance
          upgpAirlineLoyaltyProgramFees
          upgpAirlineLoyaltyProgramEarnPointsmiles
          upgpAirlineLoyaltyProgramHomeCountry
          upgpAirlineLoyaltyProgramMajorHubs
          upgpAirlineLoyaltyProgramName
          upgpAirlineLoyaltyProgramPointsAreWorth
          upgpAirlineLoyaltyProgramRedeemPointsmiles
          upgpAirlineLoyaltyProgramReview
          upgpAirlineLoyaltyProgramSignatureLounge
          upgpAirlineLoyaltyProgramSummaryTableTitle
          upgpAirlineLoyaltyProgramWebsite
          upgpAirlineLoyaltyProgramAwardChartLink
        }
      }
    }
  }
  query Post($id: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostData
    }
  }
`;
